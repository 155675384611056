const photoData = [
    {
        id: 0,
        srcSmall: "https://i.ibb.co/mz2t35Q/kids-10.jpg",
        srcLarge: "https://i.ibb.co/XFyXyL5/kids-10.jpg"
    },
    {
        id: 1,
        srcSmall: "https://i.ibb.co/GF6HfhG/kids-46.jpg",
        srcLarge: "https://i.ibb.co/Tmbwd3k/kids-46.jpg",
        
    },
    {
        id: 2,
        srcSmall: "https://i.ibb.co/6bDK4w6/kids-17.jpg",
        srcLarge: "https://i.ibb.co/QXX6PCC/kids-17.jpg"
    },
    {
        id: 3,
        srcSmall: "https://i.ibb.co/KW1ZXxz/kids-29.jpg",
        srcLarge: "https://i.ibb.co/pZQCs4v/kids-29.jpg"
    },
    {
        id: 4,
        srcSmall: "https://i.ibb.co/DMnz0mF/kids-41.jpg",
        srcLarge: "https://i.ibb.co/FDHQTbw/kids-41.jpg"
    },
    {
        id: 5,
        srcSmall: "https://i.ibb.co/ZWSQ6xz/kids-6.jpg",
        srcLarge: "https://i.ibb.co/smcR3zb/kids-6.jpg"
    },
    {
        id: 6,
        srcSmall: "https://i.ibb.co/TYLD0MN/kids-52.jpg",
        srcLarge: "https://i.ibb.co/k4WyZXS/kids-52.jpg"
    },
    {
        id: 7,
        srcSmall: "https://i.ibb.co/68m5TDj/kids-37.jpg",
        srcLarge: "https://i.ibb.co/r6702Fw/kids-37.jpg"
    },
    {
        id: 8,
        srcSmall: "https://i.ibb.co/n6NjXK5/kids-26.jpg",
        srcLarge: "https://i.ibb.co/PGNsKkj/kids-26.jpg"
    },
    {
        id: 9,
        srcSmall: "https://i.ibb.co/7QK685N/kids-22.jpg",
        srcLarge: "https://i.ibb.co/FDHQTbw/kids-41.jpg"
    },
    {
        id: 10,
        srcSmall: "https://i.ibb.co/K97RkHh/kids-14.jpg",
        srcLarge: "https://i.ibb.co/DCNfgCf/kids-14.jpg"
    },
    {
        id: 11,
        srcSmall: "https://i.ibb.co/9q4rwkN/kids-34.jpg",
        srcLarge: "https://i.ibb.co/WfFVXhw/kids-34.jpg"
    },
    {
        id: 12,
        srcSmall: "https://i.ibb.co/xFTWXpB/kids-38.jpg",
        srcLarge: "https://i.ibb.co/s5vYNjz/kids-38.jpg"
    },
    {
        id: 13,
        srcSmall: "https://i.ibb.co/nLRHb5m/kids-49.jpg",
        srcLarge: "https://i.ibb.co/nB7BD61/kids-49.jpg"
    },
    {
        id: 14,
        srcSmall: "https://i.ibb.co/DKqqPKf/kids-1-2.jpg",
        srcLarge: "https://i.ibb.co/gjY2vXp/kids-1.jpg"
    },
    {
        id: 15,
        srcSmall: "https://i.ibb.co/3CdCLpt/kids-1.jpg",
        srcLarge: "https://i.ibb.co/3CdCLpt/kids-1.jpg"
    },
    {
        id: 16,
        srcSmall: "https://i.ibb.co/HBc1KFz/kids-35.jpg",
        srcLarge: "https://i.ibb.co/HBc1KFz/kids-35.jpg"
    },
    {
        id: 17,
        srcSmall: "https://i.ibb.co/wSd7MVc/kids-2-2.jpg",
        srcLarge: "https://i.ibb.co/FDHQTbw/kids-41.jpg"
    },
    {
        id: 18,
        srcSmall: "https://i.ibb.co/rF050fj/kids-3.jpg",
        srcLarge: "https://i.ibb.co/NmHBX7R/kids-3.jpg"
    },
    {
        id: 19,
        srcSmall: "https://i.ibb.co/2N3WZkh/kids-12.jpg",
        srcLarge: "https://i.ibb.co/z61X10F/kids-12.jpg"
    },
    {
        id: 20,
        srcSmall: "https://i.ibb.co/S5vkCzm/kids-7.jpg",
        srcLarge: "https://i.ibb.co/ncV4yfZ/kids-7.jpg"
    },
    {
        id: 21,
        srcSmall: "https://i.ibb.co/3F9cfJ1/kids-9.jpg",
        srcLarge: "https://i.ibb.co/X58kfrg/kids-9.jpg"
    },
    {
        id: 22,
        srcSmall: "https://i.ibb.co/VLbPFVH/kids-5.jpg",
        srcLarge: "https://i.ibb.co/kDrRPMS/kids-5.jpg"
    },
    {
        id: 23,
        srcSmall: "https://i.ibb.co/DMnz0mF/kids-41.jpg",
        srcLarge: "https://i.ibb.co/FDHQTbw/kids-41.jpg"
    },
    {
        id: 24,
        srcSmall: "https://i.ibb.co/DMnz0mF/kids-41.jpg",
        srcLarge: "https://i.ibb.co/FDHQTbw/kids-41.jpg"
    },
    {
        id: 25,
        srcSmall: "https://i.ibb.co/DMnz0mF/kids-41.jpg",
        srcLarge: "https://i.ibb.co/FDHQTbw/kids-41.jpg"
    },
    {
        id: 26,
        srcSmall: "https://i.ibb.co/DMnz0mF/kids-41.jpg",
        srcLarge: "https://i.ibb.co/FDHQTbw/kids-41.jpg"
    },
    {
        id: 27,
        srcSmall: "https://i.ibb.co/DMnz0mF/kids-41.jpg",
        srcLarge: "https://i.ibb.co/FDHQTbw/kids-41.jpg"
    },
    {
        id: 28,
        srcSmall: "https://i.ibb.co/DMnz0mF/kids-41.jpg",
        srcLarge: "https://i.ibb.co/FDHQTbw/kids-41.jpg"
    },
    {
        id: 29,
        srcSmall: "https://i.ibb.co/DMnz0mF/kids-41.jpg",
        srcLarge: "https://i.ibb.co/FDHQTbw/kids-41.jpg"
    },
    {
        id: 30,
        srcSmall: "https://i.ibb.co/DMnz0mF/kids-41.jpg",
        srcLarge: "https://i.ibb.co/FDHQTbw/kids-41.jpg"
    },
    {
        id: 31,
        srcSmall: "https://i.ibb.co/DMnz0mF/kids-41.jpg",
        srcLarge: "https://i.ibb.co/FDHQTbw/kids-41.jpg"
    },
    {
        id: 32,
        srcSmall: "https://i.ibb.co/DMnz0mF/kids-41.jpg",
        srcLarge: "https://i.ibb.co/FDHQTbw/kids-41.jpg"
    },
    {
        id: 33,
        srcSmall: "https://haykavagyan.s3-us-west-1.amazonaws.com/small/kids+(29).jpg",
        srcLarge: "https://haykavagyan.s3-us-west-1.amazonaws.com/small/kids+(29).jpg"
    },
]

export default photoData