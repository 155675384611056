import './Footer.css'


function Footer() {
    return(
        <div className="Footer-container">
            <div className="Footer-title">
                <p><span>HAYK</span>  AVAGYAN</p>
                <div className="Copyright"><p>Copyright &copy; 2021 HaykAvagyan </p></div>
            </div>
        </div>
    )
}


export default Footer