import './Space1.css'


function Space1() {
    return(
        <div className="Space1-container">
            <div className="Space-bg">
                <div className="text">
                    <p>"You don't take a photograph you make it".</p>
                    <p className='adams'>- Ansel Adams</p>
                </div>

                
            </div>
        </div>
    )
}

export default Space1;




