import './Space2.css'


function Space2() {
    return(
        <div className="Space2-container">
            <div className="Space2-bg">
                <div className="text">
                    <p>“There are no rules for good photographs, there are only good photographs.”</p>
                    <p className='adams'>- Ansel Adams</p>
                </div>

                
            </div>
        </div>
    )
}

export default Space2;




